import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

const SIZES = ['size1', 'size2'];
const HTML_MARKUP_OPTIONS = { button: 'button', a: 'a' };

const sizes = {
  size1: 'text-base',
  size2: 'text-sm',
};

const TabNavItem = forwardRef(
  (
    {
      active = false,
      as,
      className,
      disabled = false,
      size = 'size1',
      onClick = noop,
      ...rest
    },
    ref
  ) =>
    createElement(HTML_MARKUP_OPTIONS[as] || 'button', {
      ref,
      className: twMerge(
        'flex select-none items-center justify-center px-10 py-2 font-bold outline-none transition-all ease-in hover:text-neutral-low-300 hover:shadow-[inset_0_-0.25rem_0_theme(colors.primary.200)] focus:text-neutral-low-300 focus:outline-none active:text-neutral-low-300 active:outline-none dark:focus:text-neutral-high-100 dark:active:text-neutral-high-100',
        sizes[size],
        disabled &&
          'cursor-not-allowed text-neutral-high-500 hover:text-neutral-high-500 hover:shadow-none focus:shadow-none dark:text-neutral-high-500 dark:hover:text-neutral-high-500',
        active &&
          'pointer-events-none cursor-auto text-neutral-low-300 shadow-[inset_0_-0.25rem_0_theme(colors.primary.300)] focus:shadow-[inset_0_-0.25rem_0_theme(colors.primary.300)] dark:text-neutral-high-100 dark:shadow-[inset_0_-0.25rem_0_theme(colors.primary.300)] focus:dark:shadow-[inset_0_-0.25rem_0_theme(colors.primary.300)]',
        !disabled &&
          !active &&
          'cursor-pointer text-neutral-low-100 dark:text-neutral-high-200',
        className
      ),
      disabled,
      onClick,
      ...rest,
    })
);

TabNavItem.displayName = 'TabNavItem';

TabNavItem.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Render component as an active tab
   */
  active: PropTypes.bool,
  /**
   * Render a tab as disabled
   */
  disabled: PropTypes.bool,
  /**
   * Renders a text with a determined size
   */
  size: PropTypes.oneOf(SIZES),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onClick` event
   */
  onClick: PropTypes.func,
};

export default TabNavItem;
