import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import TopbarSearch from '../TopbarSearch';
import TopbarMainRightNav from './TopbarMainRightNav';

import APP from 'constants/app';
import LINKS from 'constants/links';

const LOGO_PATH = `${APP.LOCAL_IMAGE_PATH}/features/topbar/promobit-logo.svg`;

const TopbarMain = ({ gaEventCategory }) => (
  <div className="relative z-40 flex w-full items-center justify-center bg-neutral-high-100 px-8 py-4 dark:bg-neutral-low-500">
    <div className="flex w-full items-center justify-between gap-6 3xl:w-screen-3xl">
      <NextLink href={LINKS.HOME} prefetch={false} passHref>
        <Anchor>
          <Image
            alt="Promobit"
            height={33}
            loading={null}
            sizes="(max-width: 1024px) 1px, 180px"
            src={LOGO_PATH}
            srcSet={`${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w, ${LOGO_PATH} 180w`}
            width={180}
            priority
          />
        </Anchor>
      </NextLink>
      <TopbarSearch id="search-topbar" />
      <TopbarMainRightNav gaEventCategory={gaEventCategory} />
    </div>
  </div>
);

TopbarMain.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarMain;
