import { getCookie, setCookie } from 'cookies-next';

const COOKIE_VENDOR_PREFIX = 'pr';
export const EXPERIMENT_ALPHABET = [...'abcdefghijklmnopqrstuvwxyz'];

/**
 *
    Incremental id. Last one used should be checked history.
    id: '00001',
    description: 'Change coupons area text',
    endDate: new Date('4/23/24'),
    startDate: new Date('4/13/24'),
    Each array item is a variant. Number refers to the percentage chance
     each item has to be selected.
    variants: [20, 80],
 */
export const CURRENT_ACTIVE_AB_EXPERIMENTS = [
  {
    id: '00017',
    description:
      'Validate experiments tooling for Home and Offer pages (AAA Test)',
    startDate: new Date('12/010/2024'),
    endDate: new Date('12/28/2024'), // 2 weeks
    // eslint-disable-next-line no-loss-of-precision
    variants: [33.333333333333333, 33.333333333333333, 33.333333333333333],
  },
];

export const getVariantCookieKey = (expId) =>
  `${COOKIE_VENDOR_PREFIX}_exp_${expId}_var`;

export const getVariant = (variants) => {
  const percentageLimit = 100;
  const totalWeight = variants.reduce((a, b) => a + b, 0);

  if (totalWeight !== percentageLimit) {
    throw new Error(
      `The sum of 'variants' does not equals ${percentageLimit}. Please provide a valid array containing items that summed equals exactly ${percentageLimit}. This is needed for the experiment to be distributed correctly.`
    );
  }

  let n = Math.random() * totalWeight;
  let i = null;

  variants.find((_, index) => {
    n = n - variants[index];
    i = n <= 0 && index;
    return n <= 0;
  });

  return EXPERIMENT_ALPHABET[i];
};

export const getExpMetaById = (expId) =>
  CURRENT_ACTIVE_AB_EXPERIMENTS.find((item) => item.id === expId);

export const setExperimentCookie = (expId) => {
  const currExpMeta = getExpMetaById(expId);

  setCookie(getVariantCookieKey(expId), getVariant(currExpMeta.variants), {
    expires: currExpMeta.endDate,
    sameSite: 'lax',
    secure: true,
  });
};

export const getServerExperimentProps = ({ expId, req, res }) => ({
  [expId]: getCookie(getVariantCookieKey(expId), { req, res }) || null,
});
